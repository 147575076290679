import React, { useRef, useState } from "react";
import classes from "./CigarModal.module.css";

import addImage from "../../../../../assets/addImage.png";
import TextField from "@mui/material/TextField";
import Card from "../../../../Common/Card/Card";
import AuthContext from "../../../../Common/auth-context";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const AddCigar = (props) => {
 
  const authCtx = React.useContext(AuthContext);

  const fileInputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const [id, setId] = useState(authCtx.selectedItem.id);
  const [images, setImages] = useState(authCtx.selectedItem.images || [null, null, null, null]);
  const [viewImages, setViewImages] = useState(authCtx.selectedItem.images || [null, null, null, null]);
  const [name, setName] = useState(authCtx.selectedItem.name || "");
  const [description, setDescription] = useState(authCtx.selectedItem.description || "");
  const [price, setPrice] = useState(authCtx.selectedItem.price || "");
  const [newarrival, setNewarrival] = useState(authCtx.selectedItem.newarrival || false);
  const [outOfStock, setoutOfStock] = useState(authCtx.selectedItem.outOfStock || false);
  const [mostpopular, setMostpopular] = useState(authCtx.selectedItem.mostpopular || false);
  const [size, setSize] = useState(authCtx.selectedItem.size || "");
  const [color, setColor] = useState(authCtx.selectedItem.color || "");

  const handleImageChange = (index, event) => {
    const selectedImage = event.target.files[0];
  
    if (!selectedImage) return;
  
    const reader = new FileReader();
    reader.readAsDataURL(selectedImage); // Convert to Base64
  
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
  
      img.onload = () => {
        // Create canvas element
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        // Set canvas dimensions (reduce size for compression)
        const MAX_WIDTH = 800; // Set max width
        const scaleSize = MAX_WIDTH / img.width;
        canvas.width = MAX_WIDTH;
        canvas.height = img.height * scaleSize;
  
        // Draw image to canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
        // Convert canvas to compressed Base64
        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7); // 0.7 = 70% quality
  
        // Update state with compressed image
        const updatedImages = [...images];
        updatedImages[index] = compressedBase64;
  
        const updatedviewImages = [...viewImages];
        updatedviewImages[index] = compressedBase64;
  
        setImages(updatedImages);
        setViewImages(updatedviewImages);
      };
    };
  };

  const addHookahBtn = () => {
    if (!authCtx.editmood) {
      const data = {
        key: authCtx.cigar.length + 1,
        name: name,
        description: description,
        price: price,
        newarrival: newarrival,
        outOfStock: outOfStock,
        mostpopular: mostpopular,
        color: color,
        size: size,
        type: "cigar",
        images: images
      };
      authCtx.addCigar(data);
      authCtx.setOpenCigarModal(false);
    } else {
      const data = {
        name: name,
        description: description,
        price: price,
        newarrival: newarrival,
        outOfStock: outOfStock,
        mostpopular: mostpopular,
        color: color,
        size: size,
        type: "cigar",
        images: images
      };
      authCtx.updateCigar(id, data);
      authCtx.setOpenCigarModal(false);
    }
  };

  return (
    <div>
      <Card className={classes.card1}>
        <div className={classes.inputs_location_container}>
          <div className={classes.info_rows}>
            <TextField
              id="name"
              label="Name*"
              type="search"
              variant="standard"
              value={name}
              className={classes.input_description}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <TextField
              id="price"
              label="Price*"
              type="search"
              variant="standard"
              value={price}
              className={classes.input_description}
              onChange={(e) => {
                setPrice(e.target.value);
              }}
            />
            <TextField
              id="description"
              label="Description *"
              type="search"
              variant="standard"
              multiline
              rows={3}
              value={description}
              className={classes.input_description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            {/* <TextField
              id="size"
              label="Size*"
              type="search"
              variant="standard"
              value={size}
              className={classes.input_description}
              onChange={(e) => {
                setSize(e.target.value);
              }}
            />
            <TextField
              id="color"
              label="Color*"
              type="search"
              variant="standard"
              value={color}
              className={classes.input_description}
              onChange={(e) => {
                setColor(e.target.value);
              }}
            /> */}
<FormGroup className={classes.checkboxes}>
  <FormControlLabel
    control={<Checkbox checked={outOfStock} onChange={(e) => setoutOfStock(e.target.checked)} />}
    label="Out of Stock"
  />
  <FormControlLabel
    control={<Checkbox checked={newarrival} onChange={(e) => setNewarrival(e.target.checked)} />}
    label="New Arrival"
  />
  <FormControlLabel
    control={<Checkbox checked={mostpopular} onChange={(e) => setMostpopular(e.target.checked)} />}
    label="Most popular"
  />
</FormGroup>

          </div>
          <div className={classes.imagee_container}>
          {viewImages.map((image, index) => (
              <div key={index}>
                <input
                  type="file"
                  ref={fileInputRefs[index]}
                  style={{ display: "none" }}
                  onChange={(event) => handleImageChange(index, event)}
                />
                {!image && (
                  <img
                    src={addImage}
                    alt=""
                    style={{width:'100%', height:'7em', objectFit:'fill'}}
                    onClick={() => fileInputRefs[index].current.click()}
                  />
                )}
                {image && (
                  <img
                    src={viewImages[index]}
                    alt=""
                    style={{width:'100%', height:'7em', objectFit:'fill'}}
                    onClick={() => fileInputRefs[index].current.click()}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </Card>

      <div className={classes.btns}>
        <button className={classes.btncancel} onClick={props.onClose}>
          Cancel
        </button>
        <button className={classes.btnsave} onClick={addHookahBtn}>
          Save
        </button>
      </div>
    </div>
  );
};

export default AddCigar;
