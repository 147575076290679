import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
  addDoc,
  deleteDoc,
  updateDoc
} from "firebase/firestore"; 
import { initializeApp } from "firebase/app";
import { ref, getDownloadURL, uploadBytes, getStorage } from "firebase/storage"
import { firebaseConfig } from './config';


const AuthContext = React.createContext({
  selectedItem:{},
  allCategories: [],
  setselectedItem: () => { },
  isLoggedIn: false,
  openHomeModal: false,
  openPipeModal: false,
  openAshtrayModal: false,
  openLighterModal: false,
  openLHumidifierModal: false,
  openCigarModal: false,
  openAccessoriesModal: false,
  isLoaded: false,
  setisLoaded:(isLoaded) => { },
  onLogin: () => { },
  onLogout: () => { },
  setOpenHomeModal: (openHomeModal) => { },
  setHome: () => {}, 
  setOpenPipeModal: (openPipeModal) => { },
  setallCategories: () => {},
  setPipe: () => {},
  setOpenAshtrayModal: (openAshtrayModal) => { },
  setAshtray: () => {},
  setOpenLighterModal: (openLighterModal) => { },
  setLighter: () => {},
  setOpenHumidifierModal: (openHumidifierModal) => { },
  setHumidifier: () => {},
  setOpenCigarModal: (openCigarModal) => { },
  setCigar: () => {},
  setOpenAccessoriesModal: (openAccessoriesModal) => { },
  setAccessories: () => {},
});

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration


// Initialize Firebase

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [editmood, seteditmood] = useState(false);
  const [openHomeModal, setOpenHomeModal] = useState(false);
  const [openPipeModal, setOpenPipeModal] = useState(false);
  const [openAshtrayModal, setOpenAshtrayModal] = useState(false);
  const [openLighterModal, setOpenLighterModal] = useState(false);
  const [openCigarModal, setOpenCigarModal] = useState(false);
  const [openAccessoriesModal, setOpenAccessoriesModal] = useState(false);
  const [openHumidifierModal, setOpenHumidifierModal] = useState(false);
  const [isLoaded, setisLoaded] = useState(false);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const storage = getStorage(app);
  const auth = getAuth();

  const [user, setUser] = useState(null);
  const [allCategories, setallCategories] = useState([]);
  const [home, setHome] = useState([]);
  const [pipe, setPipe] = useState([]);
  const [humidifier, setHumidifier] = useState([]);
  const [cigar, setCigar] = useState([]);
  const [lighter, setLighter] = useState([]);
  const [ashtray, setAshtray] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [selectedItem, setselectedItem] = useState([]);


  const signInWithEmailAndPasswordHandler = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return unsubscribe;
  }, [auth]);


  const signOut = () => {
    auth.signOut().then(() => {
      setUser(null);
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
      console.log(error)
    });
  }


  const sortArray = (array) => {
    array.sort((a, b) => a.key - b.key);
  }

//Hookah table
  const addHookah = async (data) => {
    try {
      const docRef = await addDoc(collection(db, "hookah"), data);
      // Update the state with the newly added document
      setHome(prev => [...prev, { ...data, id: docRef.id }]);
    } catch (error) {
      console.error("Error adding home:", error); // Log any errors
    }
  };

  
const getHookah = async () => {
  try {
    const collectionRef = collection(db, "hookah");
    const querySnapshot = await getDocs(collectionRef);
    
    const homeData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setHome(homeData);
    setallCategories(prev => prev.concat(homeData))
    // console.log("Home data:", homeData); // Log retrieved data
  } catch (error) {
    // console.error("Error fetching home data:", error); // Log any errors
  }
};
  
const updateHookah = async (id, data) => {
  try {

      // Assuming you want to update the document with an array of URLs
  const customerRef = doc(db, "hookah", id);
  await updateDoc(customerRef, data);

    getHookah()
  } catch (error) {
    // console.error("Error updating home:", error);
    // Handle error (e.g., show a notification to the user)
  }
};

  const deleteHookah = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "hookah", id)).then(() => {
      console.log("deleted");
      setHome(home.filter((other) => other.id !== id));
    });
  }

  //Pipe table
  const addPipe = async (data) => {
    try {
      const docRef = await addDoc(collection(db, "pipe"), data);
      // Update the state with the newly added document
      setPipe(prev => [...prev, { ...data, id: docRef.id }]);
    } catch (error) {
      console.error("Error adding home:", error); // Log any errors
    }
  };

  
const getPipe = async () => {
  try {
    const collectionRef = collection(db, "pipe");
    const querySnapshot = await getDocs(collectionRef);
    
    const homeData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setPipe(homeData);
    setallCategories(prev => prev.concat(homeData))
    // console.log("Home data:", homeData); // Log retrieved data
  } catch (error) {
    // console.error("Error fetching home data:", error); // Log any errors
  }
};
  
const updatePipe = async (id, data) => {
  try {

      // Assuming you want to update the document with an array of URLs
  const customerRef = doc(db, "pipe", id);
  await updateDoc(customerRef, data);

    getPipe()
  } catch (error) {
    // console.error("Error updating home:", error);
    // Handle error (e.g., show a notification to the user)
  }
};

  
  const deletePipe = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "pipe", id)).then(() => {
      console.log("deleted");
      setPipe(pipe.filter((other) => other.id !== id));
    });
  }
  

  //Ashtray Table
  const addAshtray = async (data) => {
    try {
      const docRef = await addDoc(collection(db, "ashtray"), data);
      // Update the state with the newly added document
      setAshtray(prev => [...prev, { ...data, id: docRef.id }]);
    } catch (error) {
      console.error("Error adding home:", error); // Log any errors
    }
  };
  
const getAshtray = async () => {
  try {
    const collectionRef = collection(db, "ashtray");
    const querySnapshot = await getDocs(collectionRef);
    
    const homeData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setAshtray(homeData);
    setallCategories(prev => prev.concat(homeData))
    // console.log("Home data:", homeData); // Log retrieved data
  } catch (error) {
    // console.error("Error fetching home data:", error); // Log any errors
  }
};
  
const updateAshtray = async (id, data) => {
  try {

      // Assuming you want to update the document with an array of URLs
  const customerRef = doc(db, "ashtray", id);
  await updateDoc(customerRef, data);

    getAshtray()
  } catch (error) {
    // console.error("Error updating home:", error);
    // Handle error (e.g., show a notification to the user)
  }
};

  
  const deleteAshtray = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "ashtray", id)).then(() => {
      console.log("deleted");
      setAshtray(ashtray.filter((other) => other.id !== id));
    });
  }
  
  
  //Lighter table
  const addLighter = async (data) => {
    try {
      const docRef = await addDoc(collection(db, "lighter"), data);
      // Update the state with the newly added document
      setLighter(prev => [...prev, { ...data, id: docRef.id }]);
    } catch (error) {
      console.error("Error adding home:", error); // Log any errors
    }
  };

  
const getLighter = async () => {
  try {
    const collectionRef = collection(db, "lighter");
    const querySnapshot = await getDocs(collectionRef);
    
    const homeData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setLighter(homeData);
    setallCategories(prev => prev.concat(homeData))
    // console.log("Home data:", homeData); // Log retrieved data
  } catch (error) {
    // console.error("Error fetching home data:", error); // Log any errors
  }
};

  
const updateLighter = async (id, data) => {
  try {

      // Assuming you want to update the document with an array of URLs
  const customerRef = doc(db, "lighter", id);
  await updateDoc(customerRef, data);

    getLighter()
  } catch (error) {
    // console.error("Error updating home:", error);
    // Handle error (e.g., show a notification to the user)
  }
};

  
  const deleteLighter = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "lighter", id)).then(() => {
      console.log("deleted");
      setLighter(lighter.filter((other) => other.id !== id));
    });
  }
  
  
  //Humidifier table
  const addHumidifier = async (data) => {
    try {
      const docRef = await addDoc(collection(db, "humidifier"), data);
      // Update the state with the newly added document
      setHumidifier(prev => [...prev, { ...data, id: docRef.id }]);
    } catch (error) {
      console.error("Error adding home:", error); // Log any errors
    }
  };
  
const getHumidifier = async () => {
  try {
    const collectionRef = collection(db, "humidifier");
    const querySnapshot = await getDocs(collectionRef);
    
    const homeData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setHumidifier(homeData);
    setallCategories(prev => prev.concat(homeData))
    // console.log("Home data:", homeData); // Log retrieved data
  } catch (error) {
    // console.error("Error fetching home data:", error); // Log any errors
  }
};

  const updateHumidifier = async (id, data) => {
    try {
  
        // Assuming you want to update the document with an array of URLs
    const customerRef = doc(db, "humidifier", id);
    await updateDoc(customerRef, data);
  
      getHumidifier()
    } catch (error) {
      // console.error("Error updating home:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  
  const deleteHumidifier = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "humidifier", id)).then(() => {
      console.log("deleted");
      setHumidifier(humidifier.filter((other) => other.id !== id));
    });
  }

  
  //Cigar table
  const addCigar = async (data) => {
    try {
      const docRef = await addDoc(collection(db, "cigar"), data);
      // Update the state with the newly added document
      setCigar(prev => [...prev, { ...data, id: docRef.id }]);
    } catch (error) {
      console.error("Error adding home:", error); // Log any errors
    }
  };
  
const getCigar = async () => {
  try {
    const collectionRef = collection(db, "cigar");
    const querySnapshot = await getDocs(collectionRef);
    
    const homeData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setCigar(homeData);
    setallCategories(prev => prev.concat(homeData))
    // console.log("Home data:", homeData); // Log retrieved data
  } catch (error) {
    // console.error("Error fetching home data:", error); // Log any errors
  }
};

  const updateCigar = async (id, data) => {
    try {
  
        // Assuming you want to update the document with an array of URLs
    const customerRef = doc(db, "cigar", id);
    await updateDoc(customerRef, data);
  
      getCigar()
    } catch (error) {
      // console.error("Error updating home:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };
  
  const deleteCigar = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "cigar", id)).then(() => {
      console.log("deleted");
      setCigar(cigar.filter((other) => other.id !== id));
    });
  }
  
  
  //Accessories table
  const addAccessories = async (data) => {
    try {
      const docRef = await addDoc(collection(db, "accessories"), data);
      // Update the state with the newly added document
      setAccessories(prev => [...prev, { ...data, id: docRef.id }]);
    } catch (error) {
      console.error("Error adding home:", error); // Log any errors
    }
  };
  
const getAccessories = async () => {
  try {
    const collectionRef = collection(db, "accessories");
    const querySnapshot = await getDocs(collectionRef);
    
    const homeData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setAccessories(homeData);
    setallCategories(prev => prev.concat(homeData))
    // console.log("Home data:", homeData); // Log retrieved data
  } catch (error) {
    // console.error("Error fetching home data:", error); // Log any errors
  }
};

  const updateAccessories = async (id, data) => {
    try {
  
        // Assuming you want to update the document with an array of URLs
    const customerRef = doc(db, "accessories", id);
    await updateDoc(customerRef, data);
  
      getAccessories()
    } catch (error) {
      // console.error("Error updating home:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };
  
  const deleteAccessories = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "accessories", id)).then(() => {
      console.log("deleted");
      setAccessories(accessories.filter((other) => other.id !== id));
    });
  }
  

  // Theses functions are redundant for readablity
  // If possible, Refactor them to one function with the collection name as a parameter
  // if you need help, ask Ali

  
  
  
  
  
  




  useEffect(() => {
    getHookah();
    getPipe();
    getAshtray();
    getLighter();
    getHumidifier();
    getCigar();
    getAccessories();
  }, []);





  // useEffect(() => {
  //   const storedUserLoggedInInformation = localStorage.getItem('isLoggedIn');

  //   if (storedUserLoggedInInformation === '1') {
  //     setIsLoggedIn(true);
  //   }
  // }, []);
  
  
  const openmodalHomehandler = (openHomeModal) => {
    setOpenHomeModal(openHomeModal)
  }
  
  const openmodalPipehandler = (openPipeModal) => {
    setOpenPipeModal(openPipeModal)
  }

  const openmodalAshtrayhandler = (openAshtrayModal) => {
    setOpenAshtrayModal(openAshtrayModal)
  }
  
  const openmodalLighterhandler = (openLighterModal) => {
    setOpenLighterModal(openLighterModal)
  }
  
  const openmodalHumidifierhandler = (openHumidifierModal) => {
    setOpenHumidifierModal(openHumidifierModal)
  }
  
  const openmodalCigarhandler = (openCigarModal) => {
    setOpenCigarModal(openCigarModal)
  }
  
  const openmodalAccessorieshandler = (openAccessoriesModal) => {
    setOpenAccessoriesModal(openAccessoriesModal)
  }

  const setselectedItemhandler = (selectedItem) => {
    setselectedItem(selectedItem)
  }

  const contextValue = {
    user,
    signInWithEmailAndPasswordHandler,
    seteditmood,
    setOpenHomeModal,
    setOpenPipeModal,
    setOpenAshtrayModal,
    setOpenLighterModal,
    setOpenCigarModal,
    setOpenHumidifierModal,
    setOpenAccessoriesModal,
    signOut,
    selectedItem: selectedItem,
    setselectedItem: setselectedItemhandler,
    openmodalHomehandler,
    openmodalPipehandler,
    openmodalAshtrayhandler,
    openmodalLighterhandler,
    openmodalCigarhandler,
    openmodalHumidifierhandler,
    openmodalAccessorieshandler,
    addHookah,
    addPipe,
    addAshtray,
    addLighter,
    addCigar,
    addHumidifier,
    addAccessories,
    deleteHookah,
    deletePipe,
    deleteAshtray,
    deleteLighter,
    deleteCigar,
    deleteAccessories,
    deleteHumidifier,
    updateHookah,
    updatePipe,
    updateAshtray,
    updateLighter,
    updateHumidifier,
    updateCigar,
    updateAccessories,
    isLoaded,
    setisLoaded,
    allCategories,
    home,
    pipe,
    ashtray,
    lighter,
    humidifier,
    cigar,
    accessories,
    editmood,
    openHomeModal,
    openPipeModal,
    openAshtrayModal,
    openLighterModal,
    openCigarModal,
    openHumidifierModal,
    openAccessoriesModal,
    setHome,
    setPipe,
    setallCategories,
    setAshtray,
    setLighter,
    setCigar,
    setHumidifier,
    setAccessories,
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

