import Footer from '../Footer/Footer';
import { useState } from 'react';
import Sidebar from '../SidebarSection/SideBar';
import Navbar from '../Header/Navbar';
import CartSidebar from '../Cart SideBar/CartSidebar';
import CheckOut from './CheckOut';
import Instagram from '../../Common/Instagram Section/Instagram';


function CheckOutPage() {
   const [isOpen, setIsOpen] = useState(false);
  const [isOpenHeader, setIsOpenHeader] = useState(true);
  const [cartisOpen, setcartIsOpen] = useState(false);
  // const user = useSelector((state) => state.products.userInfo);
  const [isAuthPages, setisAuthPages] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  
  const removeheader = () => {
    setIsOpenHeader(!isOpenHeader);
  };
  
  const carttoggle = () => {
      setcartIsOpen(!cartisOpen);
  };

  return (
    <div style={{display:'flex',flexDirection:"column",width:'100%'}}>
      <Navbar toggle={toggle} carttoggle={carttoggle} removeheader={removeheader} isOpenHeader={isOpenHeader}/>
      <CartSidebar isOpen={cartisOpen} toggle={carttoggle}/>
       <Sidebar isOpen={isOpen} toggle={toggle} />
       <CheckOut/>
       <Instagram/>
      <Footer />
    </div>
  );
}

export default CheckOutPage;
